import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import BTC from "../../assets/images/BTC (bitcoin).png";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTimeframe: "1M",
      series: [
        {
          name: "BTC Price",
          data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
        },
      ],
      options: {
        chart: {
          zoom: { enabled: !1 },
          toolbar: { show: !1 },
        },
        colors: ["#1877f2"],
        dataLabels: { enabled: !1 },
        stroke: { width: [3], curve: "straight" },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: [
            "01 Jan",
            "02 Jan",
            "03 Jan",
            "04 Jan",
            "05 Jan",
            "06 Jan",
            "07 Jan",
            "08 Jan",
            "09 Jan",
            "10 Jan",
            "11 Jan",
            "12 Jan",
          ],
          labels: {
            show: false, // Hide the x-axis labels
          },
          axisBorder: {
            show: false, // Hide the x-axis border
          },
          axisTicks: {
            show: false, // Hide the x-axis ticks
          },
        },
        yaxis: {
          labels: {
            show: false, // Hide the y-axis labels
          },
          axisBorder: {
            show: false, // Hide the y-axis border
          },
          axisTicks: {
            show: false, // Hide the y-axis ticks
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: function (e) {
                return e;
              },
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
          show: false, // Hide the grid lines
        },
      },
    };
  }

  handleTimeframeChange = (timeframe) => {
    this.setState({ selectedTimeframe: timeframe });
    // Logic to update the chart data based on the selected timeframe can be added here
  };

  render() {
    return (
      <React.Fragment>
        <div className="Bitcoin-content">
          <Row className="justify-content-center">
            <Col lg={10}>
              <h1 className="d-flex bitcoin-clr">
                <Link to="#" className="text-muted">
                  <i className="ri-arrow-left-line"></i>
                </Link>
                <span className="mx-2">Your Bitcoin</span>
                <ul className="list-inline user-chat-nav m-0">
                  <li className="list-inline-item">
                    <Dropdown
                      isOpen={this.state.other2}
                      toggle={() =>
                        this.setState({ other2: !this.state.other2 })
                      }
                    >
                      <DropdownToggle className="btn nav-btn" tag="i">
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">
                          <i className="ri-arrow-right-line"></i> manage name
                          of wallet
                        </DropdownItem>
                        <DropdownItem href="#">
                          <i className="ri-arrow-right-line"></i> make as
                          primary
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </li>
                </ul>
              </h1>
            </Col>
          </Row>
          <Container fluid={true} className="step-back mb-5">
            <Row>
              <Col lg={12} className="wallet-main">
                <img src={BTC} />
                <p className="mt-2 mb-0">$1523.68</p>
                <h1 className="my-2 btc-heading">0.03 BTC</h1>
                <p className="text-green">+404.40 (0.78%)</p>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col lg="12">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="270"
                />
                <Row className="justify-content-center">
                  <Col lg="6" className="date-btn">
                    <span 
                      className={`custom-btn ${this.state.selectedTimeframe === "1D" ? 'active' : ''}`}
                      onClick={() => this.handleTimeframeChange("1D")}
                    >
                      1D
                    </span>
                    <span 
                      className={`custom-btn ${this.state.selectedTimeframe === "1W" ? 'active' : ''}`}
                      onClick={() => this.handleTimeframeChange("1W")}
                    >
                      1W 
                    </span>
                    <span 
                      className={`custom-btn ${this.state.selectedTimeframe === "1M" ? 'active' : ''}`}
                      onClick={() => this.handleTimeframeChange("1M")}
                    >
                      1M 
                    </span>
                    <span 
                      className={`custom-btn ${this.state.selectedTimeframe === "1Y" ? 'active' : ''}`}
                      onClick={() => this.handleTimeframeChange("1Y")}
                    >
                      1Y
                    </span>
                    <span 
                      className={`custom-btn ${this.state.selectedTimeframe === "ALL" ? 'active' : ''}`}
                      onClick={() => this.handleTimeframeChange("ALL")}
                    >
                      ALL
                    </span>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg="12" className="text-center">
                    <Button className="cryto-btn m-2">
                      <p className="m-0">Send</p>
                    </Button>
                    <Button color="secondary" className="bitcoin-BTN m-2">
                      <p className="m-0">Receive</p>
                    </Button>
                    <Button color="secondary" className="m-2 bitcoin-BTN">
                      <p className="m-0">Swap</p>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Wallet;
