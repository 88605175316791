import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  LOGOUT_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_FAILURE,
  CHANGE_AVATAR_REQUEST,
  CHANGE_AVATAR_SUCCESS,
  CHANGE_AVATAR_FAILURE,
  SET_LOADING,
  GET_WORD_POOL_SUCCESS
} from "./actionTypes";

const initialState = {
  user: {},
  isAuthenticated: false,
  error: null,
  isLoading: true,
  wordPool:[]
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
        isLoading:false,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: action.payload,
        isLoading:false,
      };
    case LOGOUT_REQUEST:
    case DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case LOGOUT_SUCCESS:
    case DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case LOGOUT_FAILURE:
    case DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case CHECK_AUTH_REQUEST:
      return { ...state, isLoading: true, error: null };
    case CHECK_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload,
        isLoading: false,
      };
    case CHECK_AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        error: action.error,
      };
    case CHANGE_AVATAR_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case CHANGE_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          identity: {
            ...state.user.identity,
            avatar: action.payload
          }
        },
        isLoading: false,
        error: null,
      };
    case CHANGE_AVATAR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_LOADING:
    return {
      ...state,
      isLoading: action.payload,
    };
    case GET_WORD_POOL_SUCCESS:
      return {
        ...state,
        wordPool: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
