import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, FormFeedback } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { Tooltip } from "react-tooltip";
import user5 from "../../assets/images/user-img.png";
import { toast } from "react-toastify";

const Form = ({ isOpen, toggle, handleSubmit, fields, handleInputChange, title }) => {
  const [hasErrors, setHasErrors] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [avatar, setAvatar] = useState(null); 
  const [errors, setErrors] = useState({}); 
  const [fieldTouched, setFieldTouched] = useState({}); 
  const fileInputRef = useRef(null);
  const validateField = (field) => {
    const value = field.value || '';
    let error = null;
    if (field.required && !value) {
      error = `${field.label} is required.`;
    } else if (field.name === 'name' && (value.length < 6 || value.length > 16)) {
      error = 'Name must be between 6 and 16 characters.';
    }
    return error;
  };
  const checkForErrors = useCallback(() => {
    const newErrors = {};
    fields.forEach(field => {
      if (field.name === 'avatar') return;
      const error = validateField(field);
      if (error) {
        newErrors[field.name] = error;
      }
    });
    setErrors(newErrors);
    setHasErrors(Object.keys(newErrors).length > 0);
  }, [fields]);
  useEffect(() => {
    if (isOpen) {
      setAvatar(null); 
      setFieldTouched({}); 
    }
  }, [isOpen]);
  const onInputChange = (e) => {
    const { name, value } = e.target;
    const updatedField = fields.find(field => field.name === name);
    updatedField.value = value;
    const error = validateField(updatedField);
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }));
    setFieldTouched(prevTouched => ({
      ...prevTouched,
      [name]: true
    }));

    setIsDirty(true);
    checkForErrors();
    handleInputChange(e);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Invalid file type. Please select a JPG, JPEG, or PNG file.");
        return;
      }
      handleInputChange(e);
      setAvatar(URL.createObjectURL(file));
    }
  };
  const openFileDialog = () => {
    fileInputRef.current.click(); 
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <div className="bg-modal">
        <ModalHeader className="modal-header-custom">
          <h2>{title}</h2>
        </ModalHeader>
        <ModalBody>
          <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
            {fields.map((field, index) => (
              <div key={index}>
                {field.name === 'avatar' ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="second-img rounded-circle"
                      style={{
                        height: '130px',
                        width: '130px',
                        backgroundImage: `url(${avatar || user5})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        cursor: 'pointer',
                      }}
                      onClick={openFileDialog}
                      data-tooltip-id="avatar-tooltip"
                      data-tooltip-content="Select avatar."
                    />
                    <Tooltip id="avatar-tooltip" />
                    <input
                      name={field.name}
                      type="file"
                      className="form-control form-text-cls"
                      id={field.name}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png"
                    />
                     {errors[field.name] &&  (
                        <FormFeedback>{errors[field.name]}</FormFeedback>
                      )}
                  </div>
                ) : (
                  <div>
                    <Label for={field.name} className="form-label-cls">{field.label}</Label>
                    <div className="input-with-icon id-input">
                      <AvInput
                        name={field.name}
                        type={field.type}
                        className={`form-control form-text-cls mb-1 ${errors[field.name] && fieldTouched[field.name] ? "is-invalid" : ""}`}
                        id={field.name}
                        placeholder={field.placeholder}
                        onChange={onInputChange}
                        value={field.value || ''}
                        validate={{
                          required: { value: field.required, errorMessage: `${field.label} is required` },
                          ...(field.minLength && { minLength: { value: field.minLength, errorMessage: `Minimum length is ${field.minLength}` } }),
                          ...(field.maxLength && { maxLength: { value: field.maxLength, errorMessage: `Maximum length is ${field.maxLength}` } })
                        }}
                      />
                      {errors[field.name] &&  (
                        <FormFeedback>{errors[field.name]}</FormFeedback>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            <ModalFooter>
              <Button className="reject-btn" onClick={toggle}>
                Cancel
              </Button>
              <Button 
                className="btn cryto-btn savebtn" 
                type="submit" 
                disabled={hasErrors || !isDirty} 
              >
                Save
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default Form;
