import React, { Component } from "react";
import { Row, Col, Container, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import cancel from "../../../assets/images/cancel.png";
import btc from "../../../assets/images/btc.png";
class EscrowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: true,
    };
  }

  handleUnderstandClick = () => {
    this.setState({ showTerms: false });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-freeze">
          {this.state.showTerms ? (
            <Container>
              <Row className="">
                <div className="cancel-point">
                <div className="width-cls">
                  <Card>
                    <CardBody className="cancel-cls">
                      <p>
                      <img src={cancel} alt="cancel"
                      onClick={this.handleUnderstandClick} />
                      </p>
                      <h2>CANCELLED</h2>
                    </CardBody>
                  </Card>
                </div>
                </div>
              </Row>
            </Container>
          ) : (
            <Container>
              <div className="crypto-content">
              <h1 className="crypto-heading m-0">
                <Link to="#" className="text-muted">
                  <img src={arrow} alt="Arrow" />
                </Link>
                <span className="mx-2">ESCROW</span>
                <img src={timer} alt="Timer" />
              </h1>
            </div>
              <Row>
                <Col lg={12}>
                  <div className="frez-cls">
                    <Card className="progrs-timer"
                    >
                      <CardBody>
                        <p>IN PROGRESS</p>
                        <h1>00:00:14:59</h1>
                      </CardBody>
                    </Card>
                  </div>
                  <Row>
                <Col lg={12}>
                  <div className="escrow-details main-scrow">
                    <div className="detail-item">
                      <span className="label">Arslan</span>
                      <span className="value">x0,0032625378727357...</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Date:</span>
                      <span className="value">02/02/2024</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Time Duration:</span>
                      <span className="value">15 minute</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Type:</span>
                      <span className="value">Bitcoin
                      <img src={btc} alt="Timer" />
                      </span>
                    </div>
                    <div className="detail-item pb-1">
                      <span className="label">Service Fee:</span>
                      <span className="value">3%</span>
                    </div>
                    <div className="detail-item pb-1">
                      <span className="label">Attachments:</span>
                      <Button className="download-btn" size="sm">
                          Download
                          <i class="fa fa-download"></i>
                      </Button>
                    </div>
                    <div className="detail-item pb-0">
                      <span className="label">Description:</span>
                    </div>
                    <div className="detail-item pb-0">
                      <span className="discription-cls">
                        Escrow Protection is in place for fixed-price jobs.
                        Before you start the project, you and the client must
                        agree to requirements, budget, and milestones.
                      </span>
                    </div>
                    <div className="detail-item">
                      <span className="label amount-cls">Amount:</span>
                      <span className="value amount-cls">0.034 BTC</span>
                    </div>
                    <div className="actions-cls text-center">
                      <Button className="me-2 reject-btn" onClick={this.handleSubmit}>
                        DISPUTE
                      </Button>
                      <Button className="btn cryto-btn" onClick={this.handleSubmit}>
                        ASK FOR RELEASE
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EscrowDetails;
