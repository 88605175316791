import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Form,
  Progress,
  Container,
} from "reactstrap";
import BTC from "../../assets/images/BTC (bitcoin).png";
import ETH from "../../assets/images/ETH (ethereum).png";
import USDT from "../../assets/images/USDT (tether).png";
import ADA from "../../assets/images/ADA (cardano).png";
import DOGE from "../../assets/images/DOGE (dogecoin).png";
import MATIC from "../../assets/images/MATIC (polygon).png";
import classnames from "classnames";
class CreateWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Wizard", link: "#" },
      ],
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      selectedCrypto: "BTC",
    };
    this.toggleTab.bind(this);
    this.toggleTabProgress.bind(this);
    this.handleCryptoChange = this.handleCryptoChange.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
    this.toggleTabProgress(tab);
  }

  toggleTabProgress(tab) {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  }

  handleCryptoChange(event) {
    this.setState({ selectedCrypto: event.target.value });
  }

  renderCryptoSelectionForm() {
    const cryptos = [
      { code: "BTC", name: "BTC (bitcoin)", price: "$51,775.20", imageUrl: BTC },
      { code: "ETH", name: "ETH (ethereum)", price: "$2,771.23", imageUrl: ETH },
      { code: "USDT", name: "USDT (tether)", price: "$1.00", imageUrl: USDT },
      { code: "ADA", name: "ADA (cardano)", price: "$0.58", imageUrl: ADA },
      { code: "DOGE", name: "DOGE (dogecoin)", price: "$0.086", imageUrl: DOGE },
      { code: "MATIC", name: "MATIC (polygon)", price: "$0.89", imageUrl: MATIC },
    ];

    return (
      <Form>
        <ul className="list-unstyled chat-list w-100">
          {cryptos.map((crypto) => (
            <React.Fragment key={crypto.code}>
              <li>
                <div className="d-flex justify-content-between">
                  <Label
                    className="form-check-label d-flex check-box container"
                    htmlFor={`crypto-${crypto.code}`}
                  >
                    <input
                      type="radio"
                      name="crypto"
                      value={crypto.code}
                      id={`crypto-${crypto.code}`}
                      checked={this.state.selectedCrypto === crypto.code}
                      onChange={this.handleCryptoChange}
                    />
                    <span className="checkmark"></span>
                    <img src={crypto.imageUrl} alt={crypto.name} className="crp-img" />
                    <p>{crypto.name}{" "}</p>
                  </Label>
                  <Label>
                    <p className="crypto-price">{crypto.price}</p>
                  </Label>
                </div>
                <hr className="crypto-hr" />
              </li>
            </React.Fragment>
          ))}
          <li>
            <div className="d-flex justify-content-between">
              <Label
                className="form-check-label d-flex check-box container"
                htmlFor="crypto-other"
              >
                <input
                  type="radio"
                  name="crypto"
                  value="other"
                  id="crypto-other"
                  checked={this.state.selectedCrypto === "other"}
                  onChange={this.handleCryptoChange}
                />
                <span className="checkmark other-check"></span>
                <p>Other</p>
              </Label>
            </div>
          </li>
        </ul>
      </Form>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="crypto-content">
          <h1 className="crypto-heading m-0">
            <i className="ri-arrow-left-line"></i>
            <span>Choose Crypto</span>
          </h1>
          <Container fluid={true} className="step-back margin-cls">
            <Row>
              <Col lg="12">
                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                  <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 1,
                        })}
                        onClick={() => {
                          this.toggleTab(1);
                        }}
                      >
                        <span className="step-number">01</span>
                        <span className="step-title">Seller Details</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 2,
                        })}
                        onClick={() => {
                          this.toggleTab(2);
                        }}
                      >
                        <span className="step-number">02</span>
                        <span className="step-title">Company Document</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 3,
                        })}
                        onClick={() => {
                          this.toggleTab(3);
                        }}
                      >
                        <span className="step-number">03</span>
                        <span className="step-title">Bank Details</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === 4,
                        })}
                        onClick={() => {
                          this.toggleTab(4);
                        }}
                      >
                        <span className="step-number">04</span>
                        <span className="step-title">Confirm Detail</span>
                      </NavLink>
                    </NavItem>
                  </ul>
                  <div id="bar" className="mt-4">
                    <Progress
                      color="success"
                      striped
                      animated
                      value={this.state.progressValue}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid={true} className="step-back">
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId={1}>
                          <Form>
                            <Row>{this.renderCryptoSelectionForm()}</Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>step 2</Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>step 3</Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    If several languages coalesce, the grammar
                                    of the resulting
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>  
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <div className={this.state.activeTab === 4 ? "next disabled text-center" : "next text-center"}>
            <button
              className="btn cryto-btn"
              to="#"
              onClick={() => {
                this.toggleTab(this.state.activeTab + 1);
              }}
            >
              Next
            </button>
          </div>  
        </div>
      </React.Fragment>
    );
  }
}

export default CreateWallet;
