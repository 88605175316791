import React, { Component } from "react";
import { Row, Col, Container, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calender from "../../../assets/images/calender.png";
import arrow from "../../../assets/images/arrow.png";
import red from "../../../assets/images/red-arrow.png";
import green from "../../../assets/images/greenarrow.png";

class EscrowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
      selectedDate: new Date(),
    };
  }

  handleCalendarClick = () => {
    this.setState((prevState) => ({ showCalendar: !prevState.showCalendar }));
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date, showCalendar: false });
  };

  render() {
    const { showCalendar, selectedDate } = this.state;

    return (
      <React.Fragment>
        <div className="page-freeze">
          <Container>
            <div className="crypto-content">
              <h1 className="crypto-heading m-0">
                <Link to="#" className="text-muted">
                  <img src={arrow} alt="Arrow" />
                </Link>
                <span className="mx-2">HISTORY</span>
                <img
                  src={calender}
                  alt="Calendar"
                  onClick={this.handleCalendarClick}
                  style={{ cursor: "pointer" }}
                />
                {showCalendar && (
                  <DatePicker
                    selected={selectedDate}
                    onChange={this.handleDateChange}
                    inline
                  />
                )}
              </h1>
              <Row>
                <Col lg={12}>
                  <div className="frez-cls">
                    <Card className="progrs-balance">
                      <CardBody>
                        <h1 className="blance">$15.68</h1>
                        <p>current balance</p>
                      </CardBody>
                    </Card>
                  </div>
                  <Row>
                    <Col lg={12} className="history-d">
                      <p>A MONTH AGO</p>
                      <div className="history-details">
                        <div className="detail-item">
                          <div className="month-detial">
                            <div className="history-arrow">
                              <img src={red} alt="Arrow" />
                            </div>
                            <span className="id-detial">
                              <h5>IDNAME12345</h5>
                              <p className="secret">
                                Quantumography secret file
                              </p>
                              <p>14 Feb 2024 | 20:24</p>
                            </span>
                          </div>
                          <span className="value">-$50.00</span>
                        </div>
                        <div className="detail-item">
                          <div className="month-detial">
                            <div className="green-arrow">
                              <img src={green} alt="Arrow" />
                            </div>
                            <span className="id-detial">
                              <h5>IDNAME12345</h5>
                              <p className="secret">Robotics Ebook file</p>
                              <p>14 Feb 2024 | 20:24</p>
                            </span>
                          </div>
                          <span className="value-cls">+$40.00</span>
                        </div>
                        <div className="detail-item">
                          <div className="month-detial">
                            <div className="green-arrow">
                              <img src={green} alt="Arrow" />
                            </div>
                            <span className="id-detial">
                              <h5>IDNAME12345</h5>
                              <p className="secret">Robotics Ebook file</p>
                              <p>14 Feb 2024 | 20:24</p>
                            </span>
                          </div>
                          <span className="value-cls">+$40.00</span>
                        </div>
                        <div className="detail-item">
                          <div className="month-detial">
                            <div className="history-arrow">
                              <img src={red} alt="Arrow" />
                            </div>
                            <span className="id-detial">
                              <h5>IDNAME12345</h5>
                              <p className="secret">
                                Quantumography secret file
                              </p>
                              <p>14 Feb 2024 | 20:24</p>
                            </span>
                          </div>
                          <span className="value">-$50.00</span>
                        </div>
                        <div className="detail-item">
                          <div className="month-detial">
                            <div className="green-arrow">
                              <img src={green} alt="Arrow" />
                            </div>
                            <span className="id-detial">
                              <h5>IDNAME12345</h5>
                              <p className="secret">Robotics Ebook file</p>
                              <p>14 Feb 2024 | 20:24</p>
                            </span>
                          </div>
                          <span className="value-cls">+$40.00</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EscrowDetails;
