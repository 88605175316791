import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Initialize the token from local storage
const token = localStorage.getItem('authToken');
if (token) {
  setAuthToken(token);
}

export default axiosInstance;
