import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";

import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import src from "../../../assets/images/create new.png";

class NewEscrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      form: {
        name: "",
        amount: "",
        date: "",
        timeDuration: "15 minute",
        payment: "",
        attachment: null,
        description: "",
      },
    };
  }
  toggleForm = () => {
    this.setState((prevState) => ({
      showForm: !prevState.showForm,
    }));
  };
  handleChange = (e) => {
    const { name, value, files } = e.target;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: files ? files[0] : value,
      },
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onMenuItemClick("/finance/escrow-details");
  };
  render() {
    return (
      <React.Fragment>
        <div className="crypto-content">
          {!this.state.showForm ? (
            <h1 className="crypto-heading m-0">
              <Link to="#" className="text-muted">
                <img src={arrow} alt="Arrow" />
              </Link>
              <span className="mx-2">ESCROW</span>
              <img src={timer} alt="Timer" />
            </h1>
          ) : (
            <h1 className="crypto-heading m-0">
              <img src={arrow} alt="Arrow" onClick={this.toggleForm} />
              <span className="mx-2">ESCROW DETAILS</span>
              <img src={timer} alt="Timer" />
            </h1>
          )}
          <Container fluid={true} className="step-back">
            {!this.state.showForm ? (
              <div className="create-new-cls">
                <div className="text-center">
                  <div className="create-new" onClick={this.toggleForm}>
                    <img src={src} alt="Create New" />
                    <div className="mt-3">
                      <h2>Create New</h2>
                    </div> 
                  </div>
                </div>
              </div>
            ) : ( 
              <Row>
                <Col lg={12}>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleSubmit}
                  >
                    <div className="detail-form">
                      <label className="form-label-cls">Name</label>
                      <AvInput
                        name="name"
                        value={this.state.form.name}
                        validate={{ required: true }}
                        onChange={this.handleChange}
                        type="text"
                        className="form-control form-text-cls"
                        id="name"
                        placeholder="Id Name"
                      />
                    </div>
                    <div className="detail-form">
                      <label className="form-label-cls">Amount</label>
                      <div className="input-group">
                      <AvInput
                        type="number"
                        className="form-control form-text-cls"
                        name="amount"
                        placeholder="0.00"
                        value={this.state.form.amount}
                        onChange={this.handleChange}
                        validate={{ required: true }}
                      />
                      <div className="input-group-append">USD</div>
                      </div>
                    </div>

                    <Row>
                      <Col lg={6} sm={12}>
                        <div className="detail-form">
                          <label className="form-label-cls">Date</label>

                          <AvInput
                            type="date"
                            className="form-control form-text-cls"
                            name="date"
                            value={this.state.form.date}
                            onChange={this.handleChange}
                            validate={{ required: true }}
                          />
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        <div className="detail-form">
                          <label className="form-label-cls">Time duration</label>
                          <select
                            className="form-control form-text-cls"
                            name="Time Duration"
                            value={this.state.form.payment}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="" selected disabled>
                              Select TimeDuration
                            </option>
                            <option value="time1">5 minute</option>
                            <option value="time2">15 minute</option>
                            <option value="time3">20 minute</option>
                            <option value="time4">30 minute</option>
                            <option value="time5">1 hour</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Col lg={12}>
                        <div className="detail-form">
                          <label className="form-label-cls">Payment</label>
                          <select
                            className="form-control form-text-cls"
                            name="payment"
                            value={this.state.form.payment}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="" selected disabled>
                              Select Wallet
                            </option>
                            <option value="wallet1">Wallet 1 52.326 USD</option>
                            <option value="wallet2">Wallet 2 52.326 USD</option>
                            <option value="wallet3">Wallet 3 23.00 USD</option>
                            <option value="wallet4">Wallet 4 52.326 USD</option>
                          </select>
                        </div>
                      </Col>
                      <div className="detail-form">
                        <label className="form-label-cls">Attachment</label>
                        <div className="custom-file">
                          <div className="file-name mr-auto form-control form-text-cls">
                            {this.state.form.attachment ? this.state.form.attachment.name : 'PDF/JPG/PNG'}
                          </div>
                          <label className="custom-file-label" htmlFor="attachment">
                            Upload File
                            <input
                              type="file"
                              className="custom-file-input"
                              id="attachment"
                              name="attachment"
                              onChange={this.handleChange}
                              required
                            />
                          </label>
                        </div>
                    </div>

                    <div className="detail-form">
                      <label className="form-label-cls">Description</label>
                      <AvInput
                        className="form-control form-text-cls"
                        name="description"
                        value={this.state.form.description}
                        onChange={this.handleChange}
                        validate={{ required: true }}
                        type="textarea"
                        placeholder="Subject Here....."
                      />
                    </div>
                    <Button type="submit" className=" d-flex mx-auto btn cryto-btn">
                      SENT REQUEST
                    </Button>
                  </AvForm>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default NewEscrow;
