import React, { Component } from "react";
import { Button } from "reactstrap";
// Redux
import { connect } from "react-redux";
import Logo from "../../components/Auth/Logo";
import AuthFooter from "../../components/Auth/AuthFooter";
import AuthIcon from "../../components/Auth/AuthIcon";
import { toast } from "react-toastify";
// import { checkLogin,loginUserSuccessful } from "../../store/actions";
import { login, getWordPool } from '../../store/actions';
import { AvForm, AvInput } from "availity-reactstrap-validation";
// import images
import withRouter from "../../components/Common/withRouter";
import axios from "../../util/axiosConfig";

class Login extends Component {
  constructor(props) {
    super(props);
    this.seedInputRef = React.createRef();
    this.state = {
      seed: "",
      identity:{},
      loading: false,
      registrationError: null,
      wordPool: props.wordPool || [],
      suggestions: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSeedChange = this.handleSeedChange.bind(this);
    this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
  }
  handleSubmit(event, values) {
    event.preventDefault();
    this.setState({ loading: true, registrationError: null });
    if (this.state.seed.trim() === "") {
      this.setState({ loading: false});
      toast.error("Seed cannot be empty");
      return;
    }
    this.props.login({ seed: this.state.seed }, () => this.props.router.navigate("/important-note"));
    this.setState({ loading: false, registrationError: null });
  }
  handleSeedChange(event) {
    const seed = event.target.value.toUpperCase();
    this.setState({ seed });
    const lastSegment = seed.split(" ").pop(); 
    this.filterWordSuggestions(lastSegment);
  }
  handleSuggestionClick(suggestion) {
    const { seed } = this.state;
    const segments = seed.split(" ");
    segments[segments.length - 1] = suggestion; 
    const newSeed = segments.join(" ");
    this.setState({ seed: newSeed, suggestions: [] }); 
  }
  async componentDidMount() {
    document.body.classList.add("auth-body-bg");
    try {
      const response = await axios.get("/user/get-word-pool");
      if (response.status === 200) {
        this.setState({ wordPool: response.data });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.response?.data?.error || "Failed to fetch word pool.";
      toast.error(errorMessage);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.wordPool !== this.props.wordPool) {
      this.setState({ wordPool: this.props.wordPool });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
    
  }
  filterWordSuggestions(query) {
    if (query.length > 0) {
      const suggestions = this.state.wordPool.filter(word => word.toUpperCase().startsWith(query.toUpperCase()))
        .slice(0, 10);
      this.setState({ suggestions });
    } else {
      this.setState({ suggestions: [] });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div>
          <div className="container-fluid p-0">
            <div className="row g-0">
              <div className="left-page">
                <div className="bg-img">
                <Logo />
                </div>
              </div>

              <div className="login-cls text-center">
                <div className="login-content">
                  <AuthIcon />
                  <h1>Log In</h1>
                  <div className="txt-cls">
                    <label for="seed" className="label-cls mt-3">Key Seed</label>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={this.handleSubmit}
                    >
                      <AvInput
                        name="seed"
                        value={this.state.seed}
                        validate={{ required: true }}
                        onChange={this.handleSeedChange}
                        type="textarea"
                        className="form-control login-input"
                        id="seed"
                        placeholder="Enter your key seed..."
                        rows={4}
                        ref={this.seedInputRef}
                      />
                       {this.state.suggestions.length > 0 && (
                        <ul className="suggestions-list">
                          {this.state.suggestions.map((suggestion, index) => (
                            <li key={index} onClick={() => this.handleSuggestionClick(suggestion)}>
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      )}
                      <div className="form-group">
                        <Button
                          color="primary"
                          className="w-md w-100 login-btn cryto-btn"
                          type="submit"
                          disabled={this.state.loading || this.state.seed.trim() === ""}
                          >
                          {this.state.loading
                            ? "Loading ..."
                            : "Next"}
                        </Button>
                      </div>
                    </AvForm>

                    <AuthFooter
                      link="/register"
                      linkText="Register"
                      text="Don't have an account?"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  wordPool: state.User.wordPool,
});

const mapDispatchToProps = {
  login,getWordPool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
