import React from "react";
// import { Link } from "react-router-dom";
import src from "../../assets/images/auth/icon.svg";

const AuthIcon = (props) => {
  const { src } = props;
  return (
    <div className='col-lg-12'>
        <img className="logo-icon pb-4" src={src} alt="logo"/>
    </div>
  );
};

AuthIcon.defaultProps = {
  src:src,
};

export default AuthIcon;
