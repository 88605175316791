import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import user1 from "../../assets/images/user-img.png";

const ContactModal = ({ isOpen, toggle, title, members, contacts, onSubmit }) => {
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 

  const handleCheckboxChange = (address) => {
    setSelectedContacts((prevSelected) =>
      prevSelected.includes(address)
        ? prevSelected.filter((addr) => addr !== address)
        : [...prevSelected, address]
    );
  };

  const handleFormSubmit = (event, values) => {
    event.preventDefault();
    onSubmit(selectedContacts, values);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); 
  };

  const filteredContacts = contacts
    .filter(contact => contact.type === "contact")
    .filter(contact => contact.name.toLowerCase().includes(searchTerm.toLowerCase())); 

  const memberAddresses = Array.isArray(members)
    ? members.map(member => member.identityId)
    : [];
  const allContactsAdded = filteredContacts.every(contact => memberAddresses.includes(contact.address));
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <div className="bg-modal">
        <ModalHeader className="modal-header-custom">
          <h2>{title}</h2>
        </ModalHeader>
        <ModalBody>
          <AvForm className="form-horizontal" onValidSubmit={handleFormSubmit}>
            
            {/* Search Input */}
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search contacts..."
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>

            {allContactsAdded ? (
              <p>No contact found.</p>
            ) : (
              filteredContacts.length === 0 ? (
                <p>No contact found.</p>
              ) : (
                filteredContacts.map((contact) => (
                  !memberAddresses.includes(contact.address) && (
                    <div key={contact.id} className="contact-item">
                      <Label className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={selectedContacts.includes(contact.address)}
                          onChange={() => handleCheckboxChange(contact.address)}
                          className="mr-2"
                        />
                        <img
                          src={contact.avatar ? contact.avatar : user1}
                          alt={contact.name}
                          className="rounded-circle avatar-sm"
                        />
                        <span className="contact-name">{contact.name.length > 16 ? `${contact.name.substring(0, 13)}...` : contact.name}</span>
                      </Label>
                    </div>
                  )
                ))
              )
            )}

            <ModalFooter>
              <Button className="reject-btn" onClick={toggle}>
                Cancel
              </Button>
              <Button className="btn cryto-btn savebtn" type="submit"
                disabled={selectedContacts.length === 0}
              >
                Save
              </Button>
            </ModalFooter>
          </AvForm>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default ContactModal;
