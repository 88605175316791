import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Label, Input, Collapse } from "reactstrap";
import user2 from "../../assets/images/user-img.png";
import user3 from "../../assets/images/circle.png";
import user5 from "../../assets/images/plus.png";
import { Link } from "react-router-dom";
import Select from "react-select";
import withRouter from '../../components/Common/withRouter';
import { logoutRequest,deleteAccountRequest,changeAvatar} from '../../store/actions';
import { toast } from "react-toastify";
import { Tooltip } from 'react-tooltip';
const storageSize = [
  {
    options: [
      { label: "200 Mb", value: "200" },
      { label: "1 GB", value: "1" },
      { label: "10 Gb", value: "10" },
      { label: "100 Gb", value: "100" },
    ],
  },
];

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user || {},
      storage_size: 200,
      dangerouseArenaState: false,
      viewSeedState: false,
      showCollapse: false,
      selectedFile: null,
      imageURL: null,
    };

    // Create a ref for file input element
    this.fileInputRef = createRef();

    this.handleStorageSize = this.handleStorageSize.bind(this);
    this.openDangerousArena = this.openDangerousArena.bind(this);
    this.openSeed = this.openSeed.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
  }
  async handleLogout(event, values) {
    event.preventDefault();
    this.setState({ loading: true });
    setTimeout(() => {
      this.props.logoutRequest(this.props.router.navigate);
  }, [1000]);
    this.setState({ loading: false, registrationError: null });
  }
  async handleDeleteAccount(event, values) {
    event.preventDefault();
    this.setState({ loading: true });
    setTimeout(() => {
      this.props.deleteAccountRequest(this.props.router.navigate);
  }, [1000]);
    this.setState({ loading: false, registrationError: null });
  }
  handleChange = (event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        identity: value,
      },
    }));
  };

  handleStorageSize = (storage_size) => {
    this.setState({ storage_size });
  };

  openDangerousArena() {
    this.setState({ dangerouseArenaState: !this.state.dangerouseArenaState });
  }

  openSeed() {
    this.setState({ viewSeedState: !this.state.viewSeedState });
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      showCollapse: !prevState.showCollapse,
    }));
  };

  // Function to handle file selection
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
        if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Invalid file type. Please select a JPG, JPEG, or PNG file.");
        return;
      }
      this.setState({
        selectedFile: file,
        imageURL: URL.createObjectURL(file),
      });
      this.props.changeAvatar(file);
    }
  };

  // Function to open file dialog
  openFileDialog = () => {
    this.fileInputRef.current.click();
  };
  handleCopy = () => {
    const { address } = this.state.user.identity;
    navigator.clipboard.writeText(address).then(
      () => {
        toast.success('Address copied successfully.');
      },
      (err) => {
        toast.error('Could not copy address: ', err);
      }
    );
  };
  render() {
    const { user, storage_size, dangerouseArenaState, viewSeedState, imageURL } = this.state;
    const avatarURL = user.identity.avatar ? user.identity.avatar : imageURL || user2;
    let divStyle = {
      height: '130px',
      width: '130px',
      backgroundImage: `url(${avatarURL})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    };

    if (window.matchMedia("(max-width:435px)").matches) {
      divStyle = {
        ...divStyle,
        height: '124px',
        width: '124px',
      };
    }

    let divStyle1 = {
      height: '110px',
      width: '110px',
      backgroundImage: `url(${user3})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      // cursor: 'pointer',
    };

    if (window.matchMedia("(max-width:435px)").matches) {
      divStyle1 = {
        ...divStyle1,
       display:'none',
      };
    }

    return (
      <React.Fragment>
        <div className="page-content page-profile">
          <div className="d-lg-flex chat-side">
            <div className="w-100 user-chat-cls mt-sm-0">
              <div className="px-lg-2">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="profile-w">
                    <div className="chat-conversation p-3 chat-conversation-height">
                      <form action="#">
                        <div className="d-flex justify-content-center id-img">
                          <div className="second-img rounded-circle" 
                            style={divStyle} 
                            onClick={this.openFileDialog} 
                            data-tooltip-id="avatar-tooltip" 
                            data-tooltip-content="Change profile image."
                          >
                            {/* {!imageURL  && <img src={user2} alt="" className="camera-img" />} */}
                          </div>
                          <Tooltip id="avatar-tooltip" />
                          <input
                            type="file"
                            ref={this.fileInputRef}
                            style={{ display: "none" }}
                            onChange={this.handleFileChange}
                            accept=".jpg,.jpeg,.png"
                          />
                          <div style={divStyle1} aria-disabled
                              //  onClick={this.openFileDialog}
                               className="mt-2">
                            <img src={user5} alt="" className="plus-img" />
                          </div>
                        </div>
                        <div className="text-center id-mar">
                        <h2 className="id-color">
                          <div className="identity-container justify-content-center">
                             {user.identity.address.substring(0, 8)}..
                            <i className="ri-qr-code-fill"></i>
                          </div>
                        </h2>
                        </div>
                        <div className="id-mar">
                          <Label className="form-label id-color">Identity Name</Label>
                          <div className="input-with-icon id-input">
                            <Input
                              type="text"
                              className="form-control"
                              value={user.identity.address}
                              readOnly
                            />
                            <i className="ri-file-copy-line input-icon pointer" 
                              data-tooltip-id="id-name-tooltip" 
                              data-tooltip-content="Copy identity name."
                              onClick={this.handleCopy}
                            ></i>
                            <Tooltip id="id-name-tooltip" />
                          </div>
                        </div>
                        <div className="id-mar">
                          <Label className="form-label id-color">Share Via Code/QR/link</Label>
                          <div className="input-with-icon id-input">
                            <Input
                              type="text"
                              className="form-control custom-placeholder"
                              placeholder="Paste code/QR/link here..."
                            />
                            <i className="ri-qr-scan-2-line input-icon"></i>
                          </div>
                        </div>
                        <hr className="info-hr" />
                        <div className="id-mar Arena-cls more-cls">
                          <Link
                            to="#"
                            onClick={this.openDangerousArena}
                            style={{ cursor: "pointer" }}
                            className="btn btn-link text-decoration-none id-color-cls Dangerous "
                          >
                            <span> Dangerous Arena</span>
                            <i className={`ri-arrow-${dangerouseArenaState ? 'down' : 'right'}-s-line`} ></i>
                          </Link>
                          <Collapse isOpen={this.state.dangerouseArenaState}>
                            <hr className="del-hr" />
                            <div>
                              <button className="btn btn-link text-danger" onClick={this.handleDeleteAccount}>
                                  Delete Account
                              </button>
                            </div>
                          </Collapse>
                        </div>
                        <div className="id-mar Arena-cls more-cls">
                          <Link to="#" onClick={this.openSeed} style={{ cursor: "pointer" }} className="btn btn-link id-color-cls Dangerous">
                            <span>View my seeds</span>
                            <i className={`ri-arrow-${viewSeedState ? 'down' : 'right'}-s-line`}></i>
                          </Link>
                          
                          <Collapse isOpen={this.state.viewSeedState}>
                          <hr className="seed-show"/>
                            <div>
                              <textarea
                                className="form-control show-seed"
                                value={this.state.user.seed}
                                readOnly
                                rows="1"
                                style={{ overflow: 'auto' }} 
                              />
                            </div>
                          </Collapse>
                        </div>

                        <div className="id-mar Arena-cls">
                          <button className="btn text-danger w-100 text-left" onClick={this.handleLogout}>
                              Log out
                          </button>
                        </div>
                        <hr className="id-hr" />
                        <div className="id-mar">
                          <div className="id-mar manger-pd">
                            <Label className="form-label id-color">Space Manager</Label>
                            <Select
                              value={storage_size}
                              onChange={this.handleStorageSize}
                              options={storageSize[0].options}
                              classNamePrefix="select2-selection"
                              styles={{
                                dropdownIndicator: (provided) => ({
                                  ...provided,
                                  color: '#1877f2',
                                  borderRadius: '20px',
                                }),
                                menu: (provided) => ({
                                  ...provided,
                                  maxHeight: '150px',
                                  backgroundColor: 'var(--pastelblue)',
                                  border: '1px solid #ccc',
                                   borderRadius: '20px'
                                }),
                                menuList: (provided) => ({
                                  ...provided,
                                  maxHeight: '150px',
                                  overflowY: 'auto',
                                  padding:'0px'
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  backgroundColor: state.isSelected ? '#1877f2' : state.isFocused ? '#1877f2' : provided.backgroundColor,
                                  color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : provided.color,
                                   borderRadius: '20px',
                                  '&:hover': {
                                    backgroundColor: '#1877f2',
                                    color: '#fff',
                                     borderRadius: '20px'
                                  },
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  user: PropTypes.object,
};
const mapStateToProps = ({User}) => ({
  user: User.user,
});

const mapDispatchToProps ={
  logoutRequest,deleteAccountRequest,changeAvatar
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
