import { call, put, takeLatest } from "redux-saga/effects";
import axios, { setAuthToken } from "../../../util/axiosConfig";
import socket from '../../../util/socket';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  DELETE_ACCOUNT_REQUEST,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  CHECK_AUTH_REQUEST,
  CHECK_AUTH_SUCCESS,
  CHECK_AUTH_FAILURE,
  CHANGE_AVATAR_REQUEST,
  CHANGE_AVATAR_SUCCESS,
  CHANGE_AVATAR_FAILURE,
  GET_WORD_POOL,
  GET_WORD_POOL_SUCCESS
} from "./actionTypes";
import { toast } from "react-toastify";
import {
  SET_LOADING,
  } from "../../contact/actionTypes";
function* loginUser(action) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield call(axios.post, "/user/login", action.payload);
    localStorage.setItem("authToken", response.data.token);
    setAuthToken( response.data.token);
    toast.success("Login successful!");
    yield put({ type: LOGIN_SUCCESS, payload: { user: response.data } });
    socket.emit('login', {
      token: response.data.token,
      defaultIdentityAddress: response.data.identity.address
    });
    if (action.payload.callback) {
      setTimeout(() => {
        action.payload.callback();
      }, 1000);
      yield put({ type: SET_LOADING, payload: false });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage = error.response?.data?.message || "Error in login account.";
    toast.error(errorMessage);
    yield put({ type: LOGIN_FAILURE, payload: error.message });
  }
}

function* deleteUserAccount({ payload: { history } }) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield call(axios.get, "/user/delete-account");
    if (response.status === 200) {
      yield put({ type: SET_LOADING, payload: false });
      localStorage.removeItem("authToken");
      const { message } = response.data;
      toast.success(message);
      yield put({ type: DELETE_ACCOUNT_SUCCESS });
      history("/login");
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage = error.response?.data?.message || "Error deleting account.";
    toast.error(errorMessage);
    yield put({ type: DELETE_ACCOUNT_FAILURE, payload: errorMessage });
  }
}
function* logoutUser({ payload: { history } }) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield call(axios.get, "/user/logout");
    if (response.status === 200) {
      yield put({ type: SET_LOADING, payload: false });
      const { message } = response.data;
      localStorage.removeItem("authToken");
      toast.success(message);
      yield put({ type: LOGOUT_SUCCESS });
      history("/login");
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage = error.response?.data?.message || "Error logging out.";
    toast.error(errorMessage);
    yield put({ type: LOGOUT_FAILURE, payload: errorMessage });
  }
}
function* checkAuth({ payload: { history } }) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      throw new Error("No auth token");
    }
    const response = yield call(axios.get, "/user/authenticated", {
      headers: {
        Authorization: authToken
      }
    });
    yield put({ type: LOGIN_SUCCESS, payload: { user: response.data } });
    yield put({ type: CHECK_AUTH_SUCCESS, payload: true });
    yield put({ type: SET_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage = error.response?.data?.message || "Authentication check failed.";
    yield put({ type: CHECK_AUTH_FAILURE, payload: errorMessage });
    localStorage.removeItem("authToken");
    yield put({ type: LOGOUT_SUCCESS });
    history("/logout");
  }
}
function* changeAvatar(payload) {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const formData = new FormData();
    formData.append("avatar", payload.payload);
    const response = yield call(axios.post, "/user/change-avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      yield put({ type: SET_LOADING, payload: false });
      const { message,avatar } = response.data;
      toast.success(message);
      yield put({ type: CHANGE_AVATAR_SUCCESS, payload: avatar});
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage =
      error.response?.data?.message ||
      error.response?.data?.error ||
      "Failed to change avatar.";
    toast.error(errorMessage);
    yield put({ type: CHANGE_AVATAR_FAILURE, payload: errorMessage });
  }
}
function* getWordPool() {
  try {
    yield put({ type: SET_LOADING, payload: true });
    const response = yield call(axios.get,"/user/get-word-pool"); 
    if (response.status === 200) {
      yield put({ type: SET_LOADING, payload: false });
      yield put({ type: GET_WORD_POOL_SUCCESS, payload: response.data });
    }
  } catch (error) {
    yield put({ type: SET_LOADING, payload: false });
    const errorMessage = error.response?.data?.message || error.response?.data?.error || "Failed to fetch word pool.";
    toast.error(errorMessage);
  }
}
export default function* authSaga() {
  yield takeLatest(CHECK_AUTH_REQUEST, checkAuth);
  yield takeLatest(LOGIN_REQUEST, loginUser);
  yield takeLatest(LOGOUT_REQUEST, logoutUser);
  yield takeLatest(DELETE_ACCOUNT_REQUEST, deleteUserAccount);
  yield takeLatest(CHANGE_AVATAR_REQUEST, changeAvatar);
  yield takeLatest(GET_WORD_POOL, getWordPool);
}
