import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import User from './auth/user/reducer';
import Forget from './auth/forgetpwd/reducer';
import chat from "./chat/reducer";
import contact from "./contact/reducer";


const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    Account,
    Login,
    Forget,
    chat,
    User,
    contact
});

export default rootReducer;