import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import btc from "../../../assets/images/btc.png";
import Modal from "../../../components/model/model"; // Adjust the path as needed

class EscrowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: true,
    };
  }

  handleUnderstandClick = () => {
    this.setState({ showTerms: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onMenuItemClick("/finance/freezed-escrow");
  };
  render() {
    const termsParagraphs = [
      "Please keep a few things in mind:",
      "Escrow Protection is in place for fixed-price jobs. Before you start the project, you and the client must agree to requirements, budget, and milestones. Resonance charges the client at the beginning of the project, and the money for a milestone is deposited in escrow.",
      "Escrow funded payments are released when the client approves work. When milestones are completed, the client can either approve work and release payment or request modifications to the work. Clients can also request that you approve the return of funds held in escrow.",
      "Resonance offers mediation services. If you do the work and the client refuses to pay, Resonance can help mediate the dispute.",
      "Please choose fixed-price jobs carefully. Only funds deposited for an active milestone are covered by Escrow Protection."
    ];
    return (
      <React.Fragment>
        <div className="page-content p-0">
          {this.state.showTerms ? (
              <Modal
              title="Terms and Conditions"
              paragraphs={termsParagraphs}
              buttonText="I UNDERSTAND"
              onButtonClick={this.handleUnderstandClick}
            />
          ) : (
            <Container fluid={true}>
            <div className="crypto-content">
              <h1 className="crypto-heading m-0">
                <Link to="#" className="text-muted">
                  <img src={arrow} alt="Arrow" />
                </Link>
                <span className="mx-2">ESCROW</span>
                <img src={timer} alt="Timer" />
              </h1>
            </div>
              <Row>
                <Col lg={12}>
                  <div className="escrow-details">
                    <div className="detail-item">
                      <span className="label">Arslan</span>
                      <span className="value">x0,0032625378727357...</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Date:</span>
                      <span className="value">02/02/2024</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Time Duration:</span>
                      <span className="value">15 minute</span>
                    </div>
                    <div className="detail-item">
                      <span className="label">Type:</span>
                      <span className="value">Bitcoin
                      <img src={btc} alt="Timer" />
                      </span>
                    </div>
                    <div className="detail-item pb-1">
                      <span className="label">Service Fee:</span>
                      <span className="value">3%</span>
                    </div>
                    <div className="detail-item pb-1">
                      <span className="label">Attachments:</span>
                      <Button className="download-btn" size="sm">
                          Download
                          <i class="fa fa-download"></i>
                      </Button>
                    </div>
                    <div className="detail-item pb-0">
                      <span className="label">Description:</span>
                    </div>
                    <div className="detail-item">
                      <span className="discription-cls">
                        Escrow Protection is in place for fixed-price jobs.
                        Before you start the project, you and the client must
                        agree to requirements, budget, and milestones.
                      </span>
                    </div>
                    <div className="detail-item mt-3">
                      <span className="label amount-cls">Amount:</span>
                      <span className="value amount-cls">0.034 BTC</span>
                    </div>
                    <div className="actions text-center">
                      <Button className="me-2 reject-btn" onClick={this.handleSubmit}>
                        REJECT
                      </Button>
                      <Button className="btn cryto-btn" onClick={this.handleSubmit}>ACCEPT</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EscrowDetails;
