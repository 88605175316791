import React, { Component } from "react";

import { Container } from "reactstrap";

import { Link } from "react-router-dom";
// import icon from "../../assets/images/logo-dark.png";

import Logo from "../../components/Auth/Logo";
import AuthFooter from "../../components/Auth/AuthFooter";
import AuthIcon from "../../components/Auth/AuthIcon";

class Note extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <Container fluid className=" p-0">
            <div className="row g-0">
              <div className="left-page">
                <div className="bg-img">
                  <Logo />
                </div>
              </div>
              <div className="login-cls text-center">
                      <div className=" login-content">
                      <AuthIcon />
                          <h1>Important Note</h1>
                        <div className="text-center">
                         
                          <p>
                            On the next page you will see a series of 16 words.
                            This is your unique and private seed and it is the
                            ONLY way to recover your wallet in case of loss or
                            malfunction. It is YOUR responsibility to write it
                            down and store it in a safe place outside of the
                            Cake Wallet app.
                          </p>
                          <Link
                            to="/register"
                          >
                          <button color="primary"
                            className="w-md w-100 btn cryto-btn login-btn">
                            I understand, show me my seed
                          </button>
                          </Link>
                        </div>
                        <AuthFooter
                          link="/login"
                          linkText="Login"
                          text="Do you have account?"
                        />
                      </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Note;
